// extracted by mini-css-extract-plugin
export var wrapper = "search-module--wrapper--23SBo";
export var check = "search-module--check--2xiwA";
export var menuBtn = "search-module--menuBtn--Qktz-";
export var dialog = "search-module--dialog--2wP6v";
export var inner = "search-module--inner--3HMyJ";
export var input = "search-module--input--3LA47";
export var message = "search-module--message--2zJC9";
export var hitCount = "search-module--hitCount--3zUTO";
export var resultArea = "search-module--result-area--35ggc";
export var listItem = "search-module--list-item--1lz_N";
export var link = "search-module--link--nDYAz";
export var closeButton = "search-module--close-button--3fc8O";